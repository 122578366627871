import { isFuture } from "date-fns";
import { startingTotalRuneForEmission, yearlyEmissionFactor } from "../constants/thorchain";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map(edge => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function getSpotlightUrl(slug) {
  return slug ? `/network/${slug.current || slug}/` : "";
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map(child => child.text).join("");
    })
    .join("\n\n");
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getYearlyEmissions = () => {
  let remainingTotalRune = startingTotalRuneForEmission;
  let emitted = [];
  let yearlyEmissions = [];
  const year = 10;

  for (let i = 1; i <= year; i++) {
    emitted = emitted.concat(remainingTotalRune / yearlyEmissionFactor);
    remainingTotalRune = remainingTotalRune - emitted[emitted.length - 1];

    const emittedThisYear = emitted[emitted.length - 1];
    const totalRuneEmitted = startingTotalRuneForEmission - remainingTotalRune;

    yearlyEmissions = yearlyEmissions.concat({
      perYear: {
        total: emittedThisYear,
        toNodeOperators: emittedThisYear * 0.666666667,
        toLiquidityProviders: emittedThisYear * 0.333333334,
        inflationRate: (emittedThisYear / remainingTotalRune) * 100
      },
      totalRuneEmitted,
      remainingRune: remainingTotalRune
    });
  }

  return yearlyEmissions;
};

export const slugify = str =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");

export const getSinglePoolOutput = (input, inputAssetBalance, outputAssetBalance) =>
  (input * outputAssetBalance * inputAssetBalance) / (input + inputAssetBalance) ** 2;

export const fetchPrices = async currencies => {
  const response = await fetch(
    `https://api.coingecko.com/api/v3/simple/price?ids=${currencies}&vs_currencies=usd`
  );
  const data = await response.json();
  return data;
};

export const text_truncate = function(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};
